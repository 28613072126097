import { useState, useEffect, createRef } from 'react';
import './App.css';
import './styleAudio.css';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import banner from './media/micBanner.webp';
import headshot from './media/headshot.webp';
import playIcon from './media/playIcon.png';
import pauseIcon from './media/pauseIcon.png';
import audio0 from './media/commercialDemo.mp3';
import audio1 from './media/bmw.mp3';
import audio2 from './media/disney.mp3';
import audio3 from './media/eHarmony.mp3';
import audio4 from './media/juice.mp3';
import audio5 from './media/leslie.mp3';
import audio6 from './media/lush.mp3';

const trackList = [
  { sound: audio0, name: 'COMMERCIAL DEMO', len: '01:14' },
  { sound: audio1, name: 'BMW iX (Standalone)', len: '00:22' },
  { sound: audio2, name: 'Disney Princess Celebration Castle (Standalone)', len: '00:18' },
  { sound: audio3, name: 'eHarmony (Standalone)', len: '00:18' },
  { sound: audio4, name: 'Naked Juice (Standalone)', len: '00:14' },
  { sound: audio5, name: 'Leslie\'s Pool Supplies (Standalone)', len: '00:19' },
  { sound: audio6, name: 'Lush Cosmetics (Standalone)', len: '00:20' }
];

let selection = 0;
let windowSize = 2;
let player = createRef();


function App() {
  const [song, setSong] = useState(trackList[0].sound);
  const [vol, setVol] = useState(() => [RHAP_UI.VOLUME]);
  const [jump, setJump] = useState(() => true);


  useEffect(() => {
    if (window.innerWidth < 1000) {
      setVol([]);
      windowSize = 1;
    }
    if (window.innerWidth < 500) {
      setJump(false);
      windowSize = 0;
    }

    window.addEventListener('resize', handleResize);
  }, []);

  function handleResize() {
    if (window.innerWidth >= 1000) {
      if (windowSize !== 2) {
        setVol([RHAP_UI.VOLUME]);
        setJump(true);
        windowSize = 2;
      }
    }
    else if (window.innerWidth >= 500) {
      if (windowSize !== 1) {
        setVol([]);
        setJump(true);
        windowSize = 1;
      }
    }
    else if (windowSize !== 0) {
      setVol([]);
      setJump(false);
      windowSize = 0;
    }
  };

  function handlePlay() {
    if (selection === 0) {
      document.getElementById('track0').style.color = "rgb(0,87,225)";
      document.getElementById('popin0').style.opacity = "1";
    }
    for (let i = 0; i < trackList.length; i++) {
      document.getElementById(`popin${i}`).src = playIcon;
    }
    document.getElementById(`popin${selection}`).src = pauseIcon;
  };

  function handlePause() {
    document.getElementById(`popin${selection}`).src = playIcon;
  };

  function handleListClick(index) {
    if (index === selection) {
      if (player.current.audio.current.paused) {
        player.current.audio.current.play();
      }
      else player.current.audio.current.pause();
      return;
    }
    setSong(trackList[index].sound);
    listStyleUpdate(selection, index);
    selection = index;
  };

  function handlePrevClick() {
    let start = selection;
    if (player.current.audio.current.currentTime > 5) player.current.audio.current.currentTime = 0;
    else {
      if (selection === 0) {
        setSong(trackList[trackList.length - 1].sound);
        selection = trackList.length - 1;
      }
      else setSong(trackList[--selection].sound);
    }
    listStyleUpdate(start, selection);
  };

  function handleNextClick() {
    let start = selection;
    if (selection === trackList.length - 1) {
      setSong(trackList[0].sound);
      selection = 0;
    }
    else setSong(trackList[++selection].sound);
    listStyleUpdate(start, selection);
  };

  function listStyleUpdate(oldTrack, newTrack) {
    if (oldTrack === newTrack) return;
    document.getElementById(`track${oldTrack}`).style.color = "";
    document.getElementById(`popin${oldTrack}`).style.opacity = "";
    document.getElementById(`track${newTrack}`).style.color = "rgb(0,87,225)";
    document.getElementById(`popin${newTrack}`).style.opacity = "1";
  }

  let list = trackList.map((item, index) => {
    return <li id={`track${index}`} key={`key${index}`} onClick={() => handleListClick(index)}>
      <p className="track-list num">
        <img id={`popin${index}`} className="popin" src={playIcon} alt="play icon" />
        {index + 1}
      </p>
      <p className="track-list name" title={item.name}>{item.name}</p>
      <p className="track-list time">{item.len}</p>
    </li>;
  });

  return (
    <div className="App">
      <header className="App-header">
        <img src={banner} id="banner" className="App-banner" alt="banner" />
        <div id="title" className='title-text'>
          <h1>
            Adrienne
            <br></br>
            Crawford
          </h1>
          <h3>
            Email: adrienne.crawford.vo@gmail.com
            <br></br>
            Phone: (928) 202-0039
          </h3>
        </div>
      </header>
      <div id="main" className="columns">
        <div className="right-column">
          <img src={headshot} className="photo" alt="headshot" />
        </div>
        <div className="left-column">
          <h4>Voice Actor</h4>
          <br></br>
          <p>Adrienne is a first-generation Filipino-American voice actor based in Los Angeles, CA, represented by <a href='http://www.ddoagency.com'>DDO Artists Agency.</a> She records from her broadcast-quality 
             home studio with Source-Connect, and is available for your next project. She's had extensive training in all types of voice over, including ongoing private coaching 
             with legendary animation voice actor Debi Derryberry (Jimmy Neutron), voice actor Crispin Freeman (known for his iconic roles in Japanese anime, 
             games and more), and veteran commercial voice actor Marc Cashman. Adrienne is highly-trained in child voices, and is able to play female characters from toddlers
             to mature adults, as well as male characters from toddlers to mid-teens.
          </p>
          <p>She has a wide range, spanning from crisp commercial narrations, to corporate narrations, lively animation and video game characters, e-learning, audio books 
             and more. She provides dynamic, natural-sounding reads from the first take, convincing portrayal of any emotion, stellar articulation with a standard West Coast American accent,
             and high proficiency in following live direction and immediately applying it to the next take. She's also had ongoing formal training in singing since childhood, and is able to
             sing both in and out of character.
          </p>

          <div className="media-container">
            <h6 className="media-name">Adrienne Crawford</h6>
            <h5 title={trackList[selection].name}>{trackList[selection].name}</h5>
            <h6>Demo and Standalones</h6>
            <AudioPlayer
              ref={player}
              src={song}
              showSkipControls={true}
              showJumpControls={jump}
              customVolumeControls={vol}
              onPlay={handlePlay}
              onPause={handlePause}
              onClickPrevious={handlePrevClick}
              onClickNext={handleNextClick}
            />
            <ul>
              {list}
            </ul>
          </div>
        </div>
      </div>
      <div className="footerCopy">
        © 2024 Adrienne Crawford and A-Mazing Demos
      </div>
    </div>
  );
};

export default App;
